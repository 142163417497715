import React, {useEffect} from 'react';
import Header from '@/style9/HeaderForm'; // 头部 组件
import Central from '@/style9/Central'; // 表单 组件
import Banner from '@/style9/Banner';
import Message from '@/popup/Message';
import Footer from '@/footer'; // 底部内容
import { ApiPort } from '$ACTIONS/API';
import { useAuth } from '$UTILS/hooks/useAuth';
import { show } from '@ebay/nice-modal-react'
import useBanner from '$UTILS/hooks/useBanner';
import {checkAffQueryString} from "../actions/util";
import setupTraces from '$CONFIG/index';

const pageName = 'm1-001';
setupTraces(pageName);

const HayHar = ({data = {}}) => {
    const bannerList = useBanner({
        url: ApiPort.Banner1,
        fallbackImage: {
            desktop: "/static/images/m1-001/m1_lottery.jpg",
            mobile: "/static/images/m1-001/m1_lottery.jpg",
        },
    });

    const hostname = typeof window === "undefined" ? "" : window.location.hostname;

    // let traceConfig = {};
    // for (const key in traceConfigs) {
    //     if (key === pageName) {
    //         traceConfig = traceConfigs[key];
    //         break;
    //     }
    // }

    // usePiwik(traceConfig.piwik);
    // useEagleEye();
    // useIovation();
    // setupTraces(pageName)

    console.log("pageName", pageName)
    // console.log("traceConfig", traceConfig)

    useEffect(() => {
        checkAffQueryString(window.location.search);
    },[])

    return (
        <div className={`style9 pink ${pageName || 'm1-001'}`}>
            {/* 顶部 */}
            <Header
                data={data}
                logoImage={`/static/images/logo-cn.png`}
                onBefore={() => {
                    // piwik 追踪
                    global.globalGtag("Style9_QRP", "Login form", "Login_topnav", "event");
                    // dispatchPiwik("Style9_QRP", "Login form", "Login_topnav", "event");
                }}
                onSuccess={(result) => {
                    const text = result?.message || "登录成功";
                    show(Message, { title: '登录成功', text, buttonProps: { hidden: true } });
                    if(result.url) {
                        window.location.replace(result.url);
                    }
                }}
                onError={(error) => {
                    const text = error?.message || "登录失败";
                    show(Message, { title: "登录失败", text });
                    console.log("m1-001 login error", error);
                }}
            />
            {/* 表单 */}
            <Banner bannerList={bannerList} />
            <Central
                data={data}
                pageName={pageName}
                title="立即注册"
                text='下载必威 APP 完成<br>必威超级任务即可获得 123 元！'
                cssStyleConf={{isFullWidthSubmit: true, submitFontSize:'18px'}}
                onBefore={() => {
                    // piwik 追踪
                    global.globalGtag("Style9_QRP", "Registration form", "Submit_registration", "event");
                    // dispatchPiwik("Style9_QRP", "Registration form", "Submit_registration", "event");
                }}
                onSuccess={(result) => {
                    const text = result?.message || "注册成功";
                    show(Message, { title: '注册成功', text, buttonProps: { hidden: true } });
                    if (result.url) {
                        window.location.replace(result.url);
                    }
                }}
                onError={(error) => {
                    const text = error?.message || "注册失败";
                    show(Message, { title: "注册失败", text });
                    console.log("error", error);
                }}
            />
            {/* 底部 */}
            <Footer data={data}/>
        </div>
    );
};

export default HayHar;
